 
@import url('https://fonts.googleapis.com/css2?family=Paytone+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');  

@import url('https://fonts.googleapis.com/css2?family=Paytone+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); 

@import url('https://fonts.googleapis.com/css2?family=Rozha+One&display=swap');

@import "assets/libs/FontAwesome_v6.5.2/css/all.css";



/*
font-family: 'Inter', sans-serif;
font-family: 'Lexend', sans-serif;
*/

:root {
  --swiper-theme-color: #ef4f5e;
  --swiper-pagination-bullet-width: 12px;
  --swiper-pagination-bullet-height: 12px;
  --swiper-pagination-bullet-horizontal-gap: 2px;
  --swiper-pagination-bottom: 0;
  --swiper-pagination-bullet-inactive-color: #cbd5e1;
  --swiper-pagination-bullet-inactive-opacity: 1;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import "~react-image-gallery/styles/css/image-gallery.css"; */

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    transition: all 0.3s ease;
  }
  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background-color: transparent;
    transition: all 0.3s ease;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    background: rgba(239, 79, 94, 0.7);
    border-radius: 100vh;
    transition: all 0.3s ease;
  }
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(239, 79, 94, 1);
  }

  .scrollbar-0::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .scrollbar-1::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
  }
  .scrollbar-1::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
  }
  .scrollbar-1::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .custom-shadow {
    box-shadow: 0px 60px 0px 0px #f3f4f6;
  }
  .conic-gradient {
    background: conic-gradient(from -90deg, #ec2d40, #b21020, #b21020, #b21020);
  }

  swiper-slide {
    height: auto !important;
    display: flex;
  }
  .details-image swiper-slide {
    width: 80px !important;
    height: 64px !important;
  }
}

*,
*:focus {
  outline: none;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

html{overflow: scroll !important; padding-right: 0px !important;}

swiper-container.details-image {
  --swiper-navigation-size: 24px;
  --swiper-navigation-sides-offset: 0px;
  --swiper-pagination-bottom: 4px;
}
.swiper-button-next:after {
  display: none !important;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: none !important;
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Loader  */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Mini Loader  */

.miniLoader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #ec2d40;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* *********###########*************** */

.ellips-text {
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-ellips-desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.MuiInputBase-input:focus {
  box-shadow: none !important;
}
/* .sidebar-active {
  transform: translate(0%);
  position: relative;
} */

/* @media screen and (max-width: 990px){
  
  }
} */

.product-item .product-img .product-btn{
  opacity: 0;
  transition: all 0.6s ease-in-out;
}
.product-item:hover .product-img .product-btn{
  opacity: 1;
}

@media (max-width: 990px) {
  .sidebar-active {
    position: fixed;
    z-index: 10;
  }
}

.text-ellips-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-datepicker input {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 240, 1);
  font-size: 14px;
  margin-left: -1px;
  color: rgb(71, 85, 105, 1);
  padding-top: 0;
  padding-bottom: 0;
}
.MuiInputBase-root {
  height: 100%;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
  border-color: rgb(226, 232, 240, 1) !important;
}

/* error field */

.field-error {
  border: 1px solid #ec2d40;
  border-radius: 7px;
}

/* quil-editor-component */

.quil-editor-component .ql-container {
  height: 200px;
  border-radius: 0 0 5px 5px;
}
.quil-editor-component .ql-toolbar {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}
.ql-editor {
  background-color: #f8fafc;
}
.quil-editor-component .ql-toolbar {
  overflow: visible;
}
.quil-editor-component {
  overflow: hidden;
  border: 1px solid rgba(180, 180, 180, 0.408);
  border-radius: 5px;
}
.quil-editor-component .quill > div {
  border: 0;
}
.quil-editor-component .quill > div:first-child {
  border-bottom: 1px solid rgba(180, 180, 180, 0.408);
}
.ql-picker-options {
  max-height: 190px;
  overflow-y: scroll;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lexend"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lexend"]::before {
  font-family: "Lexend", sans-serif;
  content: "Lexend" !important;
}

.ql-picker.ql-size .ql-picker-label[data-value="8px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  font-family: "Lexend", sans-serif;
  content: "8" !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="9px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
  font-family: "Lexend", sans-serif;
  content: "9" !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  font-family: "Lexend", sans-serif;
  content: "10" !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  font-family: "Lexend", sans-serif;
  content: "12" !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  font-family: "Lexend", sans-serif;
  content: "14" !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  font-family: "Lexend", sans-serif;
  content: "16" !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  font-family: "Lexend", sans-serif;
  content: "20" !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  font-family: "Lexend", sans-serif;
  content: "24" !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  font-family: "Lexend", sans-serif;
  content: "32" !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="42px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="42px"]::before {
  font-family: "Lexend", sans-serif;
  content: "42" !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="54px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="54px"]::before {
  font-family: "Lexend", sans-serif;
  content: "54" !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="68px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="68px"]::before {
  font-family: "Lexend", sans-serif;
  content: "68" !important;
}
.ql-picker.ql-size .ql-picker-label[data-value="84px"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="84px"]::before {
  font-family: "Lexend", sans-serif;
  content: "84" !important;
}

/* ---------------------react image gallery-------------------------- */
.image-gallery-thumbnail-inner img {
  height: 90px;
  width: 100px;
  object-fit: cover;
  border-radius: 10px;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  min-height: calc(100vh - 500px);
  display: flex;
  align-items: center;
  /* min-height: 400px; */
}
.image-gallery-thumbnail.active {
  border: 1px solid #ec2d40;
  border-radius: 10px;
}
.image-gallery-image {
  width: 600px;
  border: 1px solid #ec2d40;
  border-radius: 10px;
  background-color: #fff6f7;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-gallery-image img {
  max-height: 350px;
  max-width: 100%;
  padding: 2px;
  margin: auto;
}
.image-gallery-right-nav .image-gallery-svg {
  height: 80px;
}
.image-gallery-left-nav .image-gallery-svg {
  height: 80px;
}


.item-container{}
.item-container .img-wraper{}
.item-container:hover .img-wraper .img{
  transform: scale(1.5);

}

/* -----------------------scroll bar ------------------------ */
.custom-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #f7949e #db0b20; /* For Firefox */

  /* For Webkit-based browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #db0b20;
  }

  &::-webkit-scrollbar-track {
    background-color: #db0b20;
  }
}
